import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from "react-router-dom";
import TablePagination from '@mui/material/TablePagination';

import { calcValue } from './values'
import {
	GfxList as GfxTableList,
	GfxItem as GfxTableItem,
} from './GfxTable'
import {
	GfxList as GfxCardsList,
	GfxItem as GfxCardsItem,
} from './GfxCards'
import { ListActions, RowActions } from './Actions'

const CSV_PARALLEL_FETCHES = 32

const displayTable = { xs:'none', sm:'block' }
const displayTableItem = { xs:'none', sm:'table-row' }
const displayCards = { xs:'block', sm:'none' }
const displayCardsItem = { xs:'block', sm:'none' }

function useQuery() {
	const { search } = useLocation()
	return React.useMemo(() => new URLSearchParams(search), [search])
}
const useQueryPage = () => {
	const query = useQuery()
	return React.useMemo(() => parseInt(query.get('page')) || 0, [query])
}

function DataItem({ def, rowActions, rowProps, id, getRow, listCheckboxes, checkboxes, setCheckboxes, autorefresh }) {
	const [ data, setData ] = useState(null)
	const [ rawData, setRawData ] = useState(null)
	const [ actionsBtns, setActionsBtns ] = useState(null)
	const [ rowSX, setRowSX ] = useState({})

	const handleRefresh = async () => {
		const data = await getRow(id)
		setRawData(data)
		setActionsBtns(rowActions ? <RowActions data={data} def={rowActions} _id={id} handleRowRefresh={handleRefresh} /> : null)
		setRowSX(rowProps ? await rowProps(data) : {})
		Promise.all(
			def.map(colDef => calcValue(colDef, data))
		).then(setData)
	}
	useEffect(() => {
		handleRefresh()

		let intervalId
		if(autorefresh)
			intervalId = setInterval(() => {
				handleRefresh()
			}, autorefresh)
		return () => intervalId && clearInterval(intervalId)
		// eslint-disable-next-line
	}, [ id ])

	const params = {
		def: def,
		actionsBtns: actionsBtns,
		listCheckboxes: listCheckboxes,
		checkboxes: checkboxes,
		setCheckboxes: setCheckboxes,
		id: id,
		data,
		rawData,
		rowSX,
	}

	return (
		<>
			<GfxTableItem {...params} sx={{ display:displayTableItem }} />
			<GfxCardsItem {...params} sx={{ display:displayCardsItem }} />
		</>
	)
}

export default function DataList({
	def, csvDef, csvName,
	ids, getRow,
	pageSize: pageSizeDefault,
	pageSizeOpts,
	rowActions, listActions,
	rowProps,
	autorefresh,
	listCheckboxes, setCheckboxes: extSetCheckboxes
}) {
	const navigate = useNavigate()
	const query = useQuery()
	const [ pageIds, setPageIds ] = useState([])
	const [ pageSize, setPageSize ] = useState(pageSizeDefault)
	const [ checkboxes, setCheckboxes ] = useState([])
	const page = useQueryPage()

	const handleChPageNum = (e, newPage) => {
		query.set('page', newPage)
		navigate('?'+query.toString())
	}
	const handleChPageSize = e => setPageSize(parseInt(e.target.value))
	const refreshPage = () => {
		if(page*pageSize >= ids.length && page>0) {
			query.set('page', 0)
			navigate('?'+query.toString())
		}
		else
			setPageIds(pageSize ? ids.slice(page*pageSize, (page+1)*pageSize) : ids)
	}
	useEffect(() => {
		extSetCheckboxes?.(checkboxes)
	}, [ checkboxes ])

	const mkCsvData = async () => {
		const data = [ csvDef.map(colDef => colDef.label) ]
		for (let i = 0; i < ids.length; i += CSV_PARALLEL_FETCHES)
			await Promise.all(
				ids
					.slice(i, i + CSV_PARALLEL_FETCHES)
					.map(async _id => {
						const dbRow = await getRow(_id)
						const dataRow = await Promise.all(
							csvDef.map(colDef => calcValue(colDef, dbRow))
						)
						data.push(dataRow)
					})
			)
		return data
	}

	useEffect(() => {
		refreshPage()
	}, [ ids, page, pageSize ])

	const showListActions = Boolean(csvDef) || Boolean(listActions?.length)
    return (
		<>
			{ showListActions && <ListActions mkCsvData={Boolean(csvDef) && mkCsvData} csvName={csvName} actions={listActions} /> }
			
			<GfxTableList def={def} rowActions={rowActions} listCheckboxes={listCheckboxes} sx={{ display:displayTable }}>
				{ pageIds.map(id => (
					<DataItem
						key={id}
						def={def}
						rowActions={rowActions}
						listCheckboxes={listCheckboxes}
						checkboxes={checkboxes}
						setCheckboxes={setCheckboxes}
						id={id}
						getRow={getRow}
						rowProps={rowProps}
						autorefresh={autorefresh}
					/>
				))}
			</GfxTableList>
			
			<GfxCardsList def={def} rowActions={rowActions} listCheckboxes={listCheckboxes} sx={{ display:displayCards }}>
				{ pageIds.map(id => (
					<DataItem
						key={id}
						def={def}
						rowActions={rowActions}
						listCheckboxes={listCheckboxes}
						checkboxes={checkboxes}
						setCheckboxes={setCheckboxes}
						id={id}
						getRow={getRow}
						rowProps={rowProps}
						autorefresh={autorefresh}
					/>
				))}
			</GfxCardsList>
			
			{ pageSizeDefault && (
				<TablePagination
					rowsPerPageOptions={pageSizeOpts || [pageSize]}
					component="div"
					count={ids.length}
					rowsPerPage={pageSize}
					page={page}
					onPageChange={handleChPageNum}
					onRowsPerPageChange={handleChPageSize}
					sx={{ minHeight:'52px', height:'52px', overflow:'hidden' }}
				/>
			)}
		</>
    )
}
