import React from 'react';
import Skeleton from '@mui/material/Skeleton';
import TableMui from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Checkbox from '@mui/material/Checkbox';

import { evalAlignment } from './values'

const mkColSX = (def, extras = {}) => {
	const sx = {
		p:1,
		textAlign: evalAlignment(def),
	}
	if(def.hide) {
		sx.display = {
			xs: def.hide.includes('xs') ? 'none' : 'table-cell',
			sm: def.hide.includes('sm') ? 'none' : 'table-cell',
			md: def.hide.includes('md') ? 'none' : 'table-cell',
			lg: def.hide.includes('lg') ? 'none' : 'table-cell',
			xl: def.hide.includes('xl') ? 'none' : 'table-cell',
		}
	}
	return { ...sx, ...extras }
}

const Head = ({ def, rowActionsActive, listCheckboxesActive }) => {
	return (
		<TableHead>
			<TableRow>
				{ listCheckboxesActive && <TableCell>&nbsp;</TableCell> }
				{ def.map((defCol, colIdx) => (
					<TableCell
						key={colIdx}
						sx={mkColSX(defCol, { fontWeight:'bold' })}
					>
						{defCol.label || ' '}
					</TableCell>
				))}
				{ rowActionsActive && <TableCell>&nbsp;</TableCell> }
			</TableRow>
		</TableHead>
	)
}

export const GfxList = ({ def, rowActions, listCheckboxes, children, sx={} }) => {
	return (
		<TableContainer component={Paper} sx={sx}>
			<TableMui sx={{ minWidth: 650 }}>
				<Head def={def} rowActionsActive={Boolean(rowActions)} listCheckboxesActive={Boolean(listCheckboxes)} />
				<TableBody>
					{children}
				</TableBody>
			</TableMui>
		</TableContainer>
	)
}

export const GfxItem = ({ id, avatar, avatarColor, def, actionsBtns, listCheckboxes, checkboxes, setCheckboxes, data, rawData, rowSX, sx={} }) => {
	const listCheckboxesEnabled = Boolean(listCheckboxes)
	const listCheckboxesActive = listCheckboxesEnabled && Boolean(listCheckboxes(rawData || {}))
	const isCheckboxChecked = checkboxes.includes(id)
	const handleCheckboxToggle = () => {
		const newList = isCheckboxChecked ? checkboxes.filter(item => item!==id) : checkboxes.concat(id)
		setCheckboxes([ ...new Set(
			newList
				.filter(Boolean)
		)])
	}
	return (
		<TableRow 
			sx={{ '&:last-child td, &:last-child th': { border: 0 }, ...sx, ...rowSX }}
		>
			{ listCheckboxesEnabled && (
				<TableCell>
					<Checkbox checked={isCheckboxChecked} onClick={handleCheckboxToggle} disabled={!listCheckboxesActive} />
				</TableCell>
			)}
			{ def.map((defCol, colIdx) => (
				<BodyCell
					key={colIdx}
					def={defCol}
					data={data ? data[colIdx] : null}
				/>
			))}
			{ actionsBtns && (
				<TableCell>
					{actionsBtns}
				</TableCell>
			)}
		</TableRow>
	)
}

const BodyCell = ({ def, data }) => {
	const sx = mkColSX(def)

	return (
		<TableCell sx={sx}>
			{ data===null ? <Skeleton variant="text" /> : data }
		</TableCell>
	)
}
