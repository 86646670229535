import React from 'react';
import { Link } from "react-router-dom";
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Skeleton from '@mui/material/Skeleton';
import { Card as CardMui, CardHeader, CardContent, CardMedia, CardActionArea, CardActions } from '@mui/material';
import Typography from '@mui/material/Typography';
// import IconButton from '@mui/material/IconButton';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
// import Tooltip from '@mui/material/Tooltip';

import { Avatar } from '../Display'

export const GfxList = ({ children, sx={} }) => (
	<Box sx={{ width: '100%', ...sx }}>
		<Grid
			container
			rowSpacing={1}
			columnSpacing={{ xs: 1, sm: 2, md: 3 }}
			justifyContent="space-evenly"
			alignItems="center"
		>
			{children}
		</Grid>
	</Box>
)

const ContentBody = ({ def, data }) =>
	def.map((defCol, colIdx) => (
		<ContentValue
			key={colIdx}
			def={defCol}
			data={data ? data[colIdx] : null}
		/>
	))

const ContentValue = ({ def, data }) => (
	<Box>
		{ Boolean(def.label) && (
			<Typography variant='body'sx={{ fontWeight:'bold', display:'inline' }}> 
				{def.label + ': '}
			</Typography>
		)}
		<Typography variant='body2' sx={{ textAlign:'right', display:'inline' }}>
			{data===null ? <Skeleton variant="text" /> : data}
		</Typography>
	</Box>
)

//TODO title, subtitle, image, linkTo, onClick
export const GfxItem = ({ avatar, avatarColor, def, actionsBtns, data, rowSX, sx={} }) => {
	const title = null
	const subtitle = null
	const image = null
	const linkTo = null
	const onClick = null

	const content = (
		<Content image={image}>
			<ContentBody def={def} data={data} />
		</Content>
	)
	return (
		<Grid item xs={12} md={6} lg={4} xl={3} sx={sx}>
			<CardMui sx={{ m:'auto', maxWidth:325, ...rowSX }}>
				{ Boolean(avatar || title) && <Header avatar={avatar} avatarColor={avatarColor} title={title} subtitle={subtitle} /> }
				{ Boolean(linkTo || onClick) ? <ContentAction linkTo={linkTo} onClick={onClick}>{content}</ContentAction> : content }
				{ Boolean(actionsBtns) && (
					<CardActions sx={{ pt:0 }}>
						<Grid container justifyContent="flex-end">{actionsBtns}</Grid>
					</CardActions>
				)}
			</CardMui>
		</Grid>
	)
}

const Header = ({ avatar, avatarColor, title, subtitle }) => {
	const avatarItem = avatar ? (
		<Avatar color={avatarColor}>{avatar}</Avatar>
	) : null
	const titleDraw = <Typography variant="subtitle">{title}</Typography>
	return (
		<CardHeader
			avatar={avatarItem}
			action={false}
			title={titleDraw}
			subheader={subtitle}
		/>
	)
}

function Content({ image, children }) {
	const content = []
	image && content.push(
		<CardMedia
			key="_cardMedia"
			component="img"
			height="140"
			image={image}
		/>
	)
	children && content.push(
		<CardContent key="_cardContent">
			{children}
		</CardContent>
	)
	return content.length ? content : null
}

function ContentAction({ linkTo, onClick, children }) {
	const actionParams = onClick ?
		{ onClick } :
		{ component:Link, to:linkTo }
	return <CardActionArea {...actionParams}>{children}</CardActionArea>
}

// function Actions({ def }) {
// 	return (
// 		<CardActions sx={{ pt:0 }}>
// 			<Grid container justifyContent="flex-end">
// 				{ def.map((action, itemIdx) => (
// 					<Action key={itemIdx} {...action} />
// 				))}
// 			</Grid>
// 		</CardActions>
// 	)
// }

// function Action({ icon, label, linkTo, onClick }) {
// 	const btn = <FontAwesomeIcon icon={icon} size="sm" />
// 	const btnParams = onClick ?
// 		{ onClick } :
// 		{ component:Link, to:linkTo }
// 	const content = <IconButton size="" {...btnParams}>{btn}</IconButton>
// 	return label ? <Tooltip title={label} placement="bottom">{content}</Tooltip> : content
// }
