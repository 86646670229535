import React from 'react';
import Box from '@mui/material/Box';
import { faFileCsv } from '@fortawesome/pro-regular-svg-icons'

import BtnPad from '../BtnPad'
import Button from '../Button'

const mkDT = () => new Date()
	.toISOString()
	.slice(0, 16)
	.replace(/-/g, '')
	.replace(/:/g, '')
	.replace(/T/, '-')

export const ListActions = ({ mkCsvData, csvName, actions }) => {
	const buildData = async () => ({
		content: await mkCsvData(),
		type: 'text/csv',
		download: csvName ? csvName + '-' + mkDT() + '.csv' : 'export.csv',
	})
	
	return (
		<Box display="flex" justifyContent="right">
			{ mkCsvData && <Button mkData={buildData} icon={faFileCsv} label="Scarica CSV" /> }
			{ Array.isArray(actions) && actions.map((action, actionIdx) => (
				<Button key={actionIdx} {...action} />
			))}
		</Box>
	)
}

export const RowActions = ({ def, data, _id, handleRowRefresh }) => {
	const defCalc = def.map(defItem => {
		let tmpBtn = {}
		for(const [key, value] of Object.entries(defItem)) {
			if(key==='onClick')
				tmpBtn[key] = async () => {
					const result = await value({ _id, handleRowRefresh, ...data })
					await handleRowRefresh()
					return result
				}
			else
				tmpBtn[key] = typeof(value)==='function' ? value({ _id, ...data }) : value
		}
		return tmpBtn
	})

	return <BtnPad def={defCalc} />
}
