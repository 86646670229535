import React from 'react';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Dialog as DialogMui, DialogTitle } from '@mui/material';

import { TplConfig } from './Template'

const Dialog = ({ title, open, handleClose, children, width }) => {
	const fullWidth = Boolean(width)
	const maxWidth = width || 'sm'
	return (
		<DialogMui onClose={handleClose} open={Boolean(open)} fullWidth={fullWidth} maxWidth={maxWidth}>
			<TplConfig backTo={handleClose}>
				{ title && <DialogTitle>{title}</DialogTitle> }
				{children}
			</TplConfig>
		</DialogMui>
	)
}
export default Dialog