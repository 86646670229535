import React from 'react';
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import Tooltip from '@mui/material/Tooltip';

const FAIcon = ({ icon, icon2 }) => (
	<span className="fa-layers fa-fw fa-lg">
		<FontAwesomeIcon icon={icon} />
		{ icon2 && <FontAwesomeIcon icon={icon2} /> }
	</span>
)

function BtnItem({ icon, icon2, label, linkTo, onClick, show=true }) {
	const display = icon ? <FAIcon icon={icon} icon2={icon2} /> : label
	return show ? (
		<Tooltip title={label}>
			<Button
				component={linkTo ? Link : null}
				to={linkTo}
				onClick={onClick}
			>
				{display}
			</Button>
		</Tooltip>
	) : null
}

export default function BtnPad({ def }) {
    return (
		<ButtonGroup variant="outlined">
			{ def.map((defItem, itemIdx) => <BtnItem key={itemIdx} {...defItem} /> ) }
		</ButtonGroup>
    )
}